import React, { createContext, useEffect, useReducer, useCallback } from 'react';
import axios from 'axios';
import * as moment from 'moment';
import UrlParse from 'url-parse';
import MessageSnackbar from './MessageSnackbar';
import AlertDlg from './AlertDlg';
import serverReducer from './serverReducer';
import platformsReducer from './platformsReducer';
import missionsReducer from './missionsReducer';
import appReducer from './appReducer';
import Main from './main';
import WS from './ws';
import { detect } from 'detect-browser';

import './App.css';

export const appContext = createContext(null);
export const serverContext = createContext(null);
export const missionsContext = createContext([]);
export const platformsContext = createContext({ availablePlatforms: [], stateUpdateTime: new Date() });
export const platformStates = {};       // MQTT info on the platform state
export const platformConfigs = {};      // MQTT info on the platform config (to be used in live low latency playback)
export const platformStreamInfos = {};  // MQTT info on the platform stream


let serverProductionPort = 8080;
const stServerHosting = true;

let serverCfg = { state: 'Offline' };
let ws;


/**
 * Fetch data from the server (GET)
 * @param  {String} url
 * @param  {Object} server
 */
export const fetchFromServer = (url, server) => {

  if (server.serverHost) {
    return axios(
      `${server.serverHost}/api/${url}`, {
      headers: { 'Authorization': `Basic ${server.token}` }
    });
  }
  else
    throw new Error('serverHost not defined');
};


/**
 * get data from the server (GET), with query params
 * @param  {String} url
 * @param  {Object} server
 */
export const getFromServer = (url, data, server) => {

  if (server.serverHost) {
    return axios(
      `${server.serverHost}/api/${url}`, {
      params: data,
      headers: { 'Authorization': `Basic ${server.token}` }
    });
  }
  else
    throw new Error('serverHost not defined');
};

/**
 * Post data to the server (POST)
  * @param {String} url 
  * @param {Object} data 
  * @param {Object} server 
 */
export const postToServer = (url, data, server) => {
  return axios.post(
    `${server.serverHost}/api/${url}`, data, {
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: { 'Authorization': `Basic ${server.token}` }
  });
};


/**
 * Post data to the server (POST)
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} server 
 */
export const postDownloadBlobFromServer = (url, data, server) => {
  return axios.post(
    `${server.serverHost}/api/${url}`, data, {
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    responseType: 'blob',
    headers: { 'Authorization': `Basic ${server.token}` }
  });
};

export const downloadFile = (server, url, filename) => {
  axios({
    url: `${server.serverHost}/api/${url}`,
    method: 'GET',
    responseType: 'blob',
    headers: { 'Authorization': `Basic ${server.token}` }
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  });
};

/**
 * Put data to the server (PUT)
  * @param {String} url 
  * @param {Object} data 
  * @param {Object} server 
 */
export const putToServer = (url, data, server) => {
  return axios.put(
    `${server.serverHost}/api/${url}`, data, {
    headers: { 'Authorization': `Basic ${server.token}` }
  });
};


/**
 * 
 * @param {String} method 
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} server 
 */
export const callServer = (method, url, data, server) => {
  return axios({
    method: method,
    url: `${server.serverHost}/api/${url}`,
    data: data,
    headers: { 'Authorization': `Basic ${server.token}` }
  })
};

/**
 * Upload data to the server (POST)
 * @param  {String} url
 * @param  {Object} server
 */
export const uploadToServer = (url, name, data, server, cbProgress) => {

  const formData = new FormData();
  data.forEach(file => formData.append(name, file));

  return axios({
    method: 'post',
    url: `${server.serverHost}/api/${url}`,
    data: formData,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
      'Authorization': `Basic ${server.token}`,
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: progressEvent => {
      if (cbProgress)
        cbProgress(progressEvent);
    }
  }
  );
};


const initialServerState = {
  state: 'Offline',
  serverName: 'N/A',
  serverVersion: 'N/A',
  serverStartTime: null,
  systemUptime: null,  // only correct at the time of reading.
  // platforms: null,
  demoMode: true,
  nodeInfo: 'N/A',
  usergroups: null,
  users: null,
  supervisorPort: null,
  onlineUsers: 0,
  conferencesCount: 0
};


const getInitialAppState = () => {

  let initialAppState = {
    alertMessage: { open: false, title: '', message: '' },
    snackbarMessage: { open: false, message: '', severity: undefined, variant: 'filled' },
  };

  return initialAppState;
}


/**
 * Get initial configuration
 */
export const getConfig = async () => {
  return new Promise(async (resolve, reject) => {
    const username = localStorage.getItem('username') || 'guest';
    const password = localStorage.getItem('password') || 'guest';
    let serverUrl;

    serverUrl = new UrlParse(window.location.origin);
    serverCfg.serverUrl = serverUrl;

    serverCfg.username = username;
    serverCfg.password = password;
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    serverCfg.token = token;

    // Set client id to distinguish between the instances for the same user 
    const min = 1;
    const max = 100000;
    const random = Math.floor(Math.random() * (+max - +min)) + +min;
    serverCfg.clientId = (`${serverCfg.username}-${random}`);

    serverCfg.serverHost = stServerHosting ? `${window.location.origin}` : `${serverUrl.protocol}//${window.location.hostname}:${serverProductionPort}`;;

    try {
      const userRoleData = await fetchFromServer(`users/authenticated`, serverCfg);
      serverCfg.userRole = userRoleData.data;

    } catch (error) {
      serverCfg.username = 'guest';
      serverCfg.password = 'guest';
      serverCfg.userRole = 'guest';
      serverCfg.token = Buffer.from(`${'guest'}:${'guest'}`, 'utf8').toString('base64');
      localStorage.setItem('username', 'guest');
      localStorage.setItem('password', 'guest');
    }

    const info = await fetchFromServer('info', serverCfg);
    serverCfg.serverVersion = info.data.serverVer;
    serverCfg.serverName = info.data.serverName;
    serverCfg.serverStartTime = new Date(info.data.serverStartTime);
    serverCfg.demoMode = info.data.demoMode;
    serverCfg.nodeInfo = info.data.nodeInfo;
    serverCfg.videoDir = info.data.videoDir;
    serverCfg.supervisorUrl = `${serverUrl.protocol}//${serverUrl.hostname}:${info.data.supervisorPort}`;
    serverCfg.clientIp = info.data.clientIp;
    serverCfg.useReverseProxy = info.data.useReverseProxy ? JSON.parse(info.data.useReverseProxy.toLowerCase()) : false;
    serverCfg.useWebRtcVideo = info.data.useWebRtcVideo ? JSON.parse(info.data.useWebRtcVideo.toLowerCase()) : false;
    serverCfg.webRtcService = info.data.webrtc_service;
    serverCfg.useLiveRtspHls = info.data.useLiveRtspHls ? JSON.parse(info.data.useLiveRtspHls.toLowerCase()) : false;
    serverCfg.useLiveLowLatency = info.data.useLiveLowLatency ? JSON.parse(info.data.useLiveLowLatency.toLowerCase()) : false;
    serverCfg.playbackDataDelay = info.data.playbackDataDelay ? JSON.parse(info.data.playbackDataDelay) : undefined;
    serverCfg.conferenceServerUrl = info.data.conferenceServerUrl;
    serverCfg.conferenceServerPort = info.data.conferenceServerPort;
    serverCfg.lk_url = info.data.lk_url;

    resolve(serverCfg);
  })
}

/**
 *  APP
 */
function App() {

  const [app, dispatchApp] = useReducer(appReducer, getInitialAppState());
  const [missions, dispatchMissions] = useReducer(missionsReducer, []);
  const [platforms, dispatchPlatforms] = useReducer(platformsReducer, { availablePlatforms: [], stateUpdateTime: new Date() });
  const [server, dispatchServer] = useReducer(serverReducer, initialServerState);

  const processSocketMsg = useCallback((topicName, message) => processServerSocketMessage(topicName, message), [] );

  useEffect(() => {
    const fetchData = async () => {

      try {

        const serverProductionPortOverride = localStorage.getItem('serverProductionPort');
        serverProductionPort = serverProductionPortOverride ? parseInt(serverProductionPortOverride) : 8080;
       
        serverCfg.browserInfo = detect();
        await getConfig();

        dispatchServer({ type: 'SET_SERVER_CFG', payload: serverCfg });

        ws = new WS(serverCfg, 'ClientApp', '', processSocketMsg);
        dispatchServer({ type: 'SET_SERVER_SOCKET', payload: ws });

        let filter;
        try {
          const filterStr = sessionStorage.getItem('queryFilter');
          if (filterStr) {
            filter = JSON.parse(filterStr);
            dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });
          }

        } catch (error) {
        }

        const serverMissions = await getFromServer('missions/brief', filter, serverCfg);
        dispatchMissions({ type: 'SET_MISSIONS', payload: translateMissions(serverMissions.data) });

        const groups = await fetchFromServer('usergroups', serverCfg);
        dispatchServer({ type: 'SET_USERGROUPS', payload: groups.data });

        const servePlatforms = await fetchFromServer('platforms', serverCfg);
        dispatchPlatforms({ type: 'SET_PLATFORMS', payload: servePlatforms.data });

        const retMessages = await fetchFromServer(`usermessages`, serverCfg);
        dispatchServer({ type: 'SET_USER_MESSAGES', payload: retMessages.data });

      } catch (error) {
        const msg = `Server access error - ${error.response ? error.response.data : error.message}`;
        console.log(msg);
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: msg, severity: 'error' } });
      }
    };

    fetchData();

  }, []);


  /**
   * 
   * @param {String} topicName 
   * @param {String} message 
   */
  async function processServerSocketMessage(topicName, message) {
    try {
      switch (topicName) {
        case 'state':
          dispatchServer({ type: 'SET_SERVER_STATE', payload: message });
          if (message === 'Offline')
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: 'Server offline', severity: 'error' } });
          break;

        case 'info':
          const startTime = new Date(message.serverStartTime);
          dispatchServer({ type: 'UPDATE_SERVER_UPTIME', payload: startTime });
          break;

        case 'platforms':
          const servePlatforms = await fetchFromServer('platforms', serverCfg);
          dispatchPlatforms({ type: 'SET_PLATFORMS', payload: servePlatforms.data });
          break;

        case 'missions':
          const serverMissions = await fetchFromServer('missions/brief', serverCfg);
          dispatchMissions({ type: 'SET_MISSIONS', payload: translateMissions(serverMissions.data) });
          break;

        case 'onlineUsers':
          dispatchServer({ type: 'SET_ONLINE_USERS', payload: message });
          break;

        case 'conferencesCount':
            dispatchServer({ type: 'SET_CONFERENCES_COUNT', payload: message });
            break;

        case 'users':
          const users = await fetchFromServer('users', serverCfg);
          dispatchServer({ type: 'SET_USERS', payload: users.data });
          break;

        case 'usergroups':
          const usergroups = await fetchFromServer('usergroups', serverCfg);
          dispatchServer({ type: 'SET_USERGROUPS', payload: usergroups.data });
          break;

        case 'mission_update':
          dispatchMissions({ type: 'UPDATE_MISSION', payload: message });
          break;

        case 'demoexpired':
          dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Demo Expired', message: 'Please check your license.' } });
          break;

         case 'concurrent_users_limit_reached':
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: `Too many concurrent users - ${message.message}`, message: 'Please check your license.', keepOpened: true } });
            break;

        case 'error':
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Server error: ${message}.`, severity: 'error' } });
          break;

        case 'streamMonitor':
          processStreamMonitorMessage(message.topic, message.message);
          break;

        case 'recorder':
          processRecorderMessage(message.topic, message.message);
          break;

        case 'message':
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Server message: ${message}.`, severity: 'warning' } });
          break;

        default:
          break
      }
    } catch (error) {

    }
  }


  /**
   * Process Recorder Message
   * @param {String} topic 
   * @param {String} message 
   */
  async function processRecorderMessage(topic, message) {

    const topicParsed = topic.split('/');
    const topicName = topicParsed.pop().toLowerCase();
    const mission = topicParsed[3];
    const sensor = topicParsed[4];

    const msgStr = `${mission}/${sensor}: ${message.replace('ffmpeg', 'recorder')}`;

    try {
      switch (topicName) {

        case 'command':
          if (message === 'start') {
            const servePlatforms = await fetchFromServer('platforms', serverCfg);
            dispatchPlatforms({ type: 'SET_PLATFORMS', payload: servePlatforms.data });
          }
          console.log(message);
          break;

        case 'state':
          console.log(msgStr);
          break;

        case 'error':
          if (message.length() > 0) { // Zero is to clean the error
            console.log(msgStr);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Recorder error: ${msgStr}`, severity: 'error' } });
          }
          break;

        case 'mission_update':
          dispatchMissions({ type: 'UPDATE_MISSION', payload: message });
          break;

        default:
          break
      }
    } catch (error) {

    }
  }

  /**
   * 
   * @param {String} topic 
   * @param {String} message 
   */
  function processStreamMonitorMessage(topic, message) {

    try {
      const topicParsed = topic.split('/');
      const platformName = topicParsed[1];
      const sensorName = topicParsed[2];
      const entity = topicParsed[3].toLowerCase();

      switch (entity) {
        case 'state':
          setPlatformState(platformName, sensorName, message);
          break;

        case 'config':
          setPlatformConfig(platformName, sensorName, message);
          break;

        case 'detection':
          setPlatformStreamInfo(platformName, sensorName, message);
          break;

        default:
          break
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  /**
   * 
   * @param {String} platformName 
   * @param {String} sensorName 
   * @param {*} state 
   */
  function setPlatformState(platformName, sensorName, state) {
    //  console.log(`${platformName}/${sensorName} - ${state}`);
    platformStates[`${platformName}/${sensorName}`] = state;

    dispatchPlatforms({ type: 'SET_PLATFORMS_UPDATE_TIME', payload: new Date() });
  }

  function setPlatformConfig(platformName, sensorName, config) {
    // console.log(`${platformName}/${sensorName} - ${config}`);
    platformConfigs[`${platformName}/${sensorName}`] = { ...JSON.parse(config), serverHostName: `${serverCfg.serverUrl.hostname}` };
    if (platforms.availablePlatforms.length > 0)
      dispatchPlatforms({ type: 'SET_PLATFORMS', payload: platforms });
  }

  function setPlatformStreamInfo(platformName, sensorName, streamInfo) {
    // console.log(`${platformName}/${sensorName} - ${config}`);
    platformStreamInfos[`${platformName}/${sensorName}`] = JSON.parse(streamInfo);
    if (platforms.availablePlatforms.length > 0)
      dispatchPlatforms({ type: 'SET_PLATFORMS', payload: platforms });
  }


  const handleMessageSnackbarClose = () => {
    dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { open: false, message: '', severity: undefined, variant: 'filled' } });
  }


  return (
    // <ThemeProvider theme={theme}>
      <appContext.Provider value={{ app, dispatchApp }}>
        <serverContext.Provider value={{ server, dispatchServer }}>
          <missionsContext.Provider value={{ missions, dispatchMissions }}>
            <platformsContext.Provider value={{ platforms, dispatchPlatforms }}>
              <Main />
              <MessageSnackbar open={app.snackbarMessage.open} message={app.snackbarMessage.message} severity={app.snackbarMessage.severity} onHandleMessageSnackbarClose={handleMessageSnackbarClose} />
              <AlertDlg
                open={app.alertMessage.open}
                alert={app.alertMessage}
                onClose={() => { 
                  if(!app.alertMessage.keepOpened) 
                    dispatchApp({ type: 'HIDE_ALERT' })
                }}
              />
            </platformsContext.Provider>
          </missionsContext.Provider>
        </serverContext.Provider>
      </appContext.Provider>
    // </ThemeProvider>
  );
}

/**
 * Translates Missions to the format used by the MissionTable
 * @param  {Array} serverMissions
 * Format example
 *  const missionsArray = [
    { id: 0, mId: 0, name: 'Mission 1', description: 'Test 1', platform: 'Heron 1', startTime: Feb 11 2020, 12:42, endTime: Feb 12 2020, 9:02 },
    { id: 1, mId: 0, parentId: 0, sensorId: 1, name: 'EO-IR', description: 'Test 1', platform: 'Heron 1', startTime: Feb 11 2020, 12:42, endTime: Feb 12 2020, 9:02 },
    { id: 2, mId: 0, parentId: 0, sensorId: 2, name: 'Tail', description: 'Test 1', platform: 'Heron 1', startTime: Feb 11 2020, 12:42, endTime: Feb 12 2020, 9:02  },
    { id: 3, mId: 1, name: 'Mission 2', description: 'Test 2', platform: 'Hermes 1', startTime: Feb 10 2020, 2:42, endTime: Feb 11 2020, 12:29 },
    { id: 4, mId: 1, parentId: 3, sensorId: 1, name: 'EO-IR', description: 'Test 1', platform: 'Hermes 1', startTime: Feb 10 2020, 2:42, endTime: Feb 11 2020, 12:29 },
    { id: 5, mId: 1, parentId: 3, sensorId: 2, name: 'Sensor 2', description: 'Test 1', platform: 'Hermes 1', startTime: Feb 10 2020, 2:42, endTime: Feb 11 2020, 12:29 },
  ];
 */
export function translateMissions(serverMissions) {

  let i = 0;
  const tableMissions = [];

  // Missions 
  //.sort(m=>m.state === 'Ready')
  serverMissions.forEach(m => {
    const missionId = i++;

    const tMission = {
      id: missionId,
      mId: m._id,
      name: m.name,
      description: m.description,
      accessToken: m.accessToken,
      thumbnail: m.thumbnail,
      rating: m.rating || 0,
      state: m.state,
      creationTime: m.creationTime,
      startTime: m.startTime ? moment.utc(m.startTime).format("MMM DD YYYY, h:mm") : undefined,
      recordingStartTime: m.recordingStartTime ? moment.utc(m.recordingStartTime).format("MMM DD YYYY, h:mm") : undefined,
      startTimeUtc: m.startTime ? moment.utc(m.startTime).valueOf() : undefined,
      endTime: m.endTime ? moment.utc(m.endTime).format("MMM DD YYYY, h:mm") : undefined,
      recordingEndTime: m.recordingEndTime ? moment.utc(m.recordingEndTime).format("MMM DD YYYY, h:mm") : undefined,
      platform: m.platform,
      country_code: m.country_code,
      country_name: m.country_name,
      platformType: m.platformType,
      tags: m.tags,
      sortOrder: m.sortOrder
    };


    let mViews = 0;
    // Sensors
    const tSensors = m.sensors.map(s => {
      mViews += s.views;
      return {
        id: i++,
        parentId: missionId,
        parentState: m.state,
        mName: m.name,
        mId: m._id,
        platform: m.platform,
        platformType: m.platformType,
        sId: s._id,
        name: s.name,
        description: s.description,
        type: s.type,
        creationTime: s.creationTime,
        startTime: s.startTime ? moment.utc(s.startTime).format("MMM DD YYYY, h:mm") : undefined,
        endTime: s.endTime ? moment.utc(s.endTime).format("MMM DD YYYY, h:mm") : undefined,
        recordingStartTime: s.recordingStartTime ? moment.utc(s.recordingStartTime).format("MMM DD YYYY, h:mm") : undefined,
        recordingEndTime: s.recordingEndTime ? moment.utc(s.recordingEndTime).format("MMM DD YYYY, h:mm") : undefined,
        views: s.views,
        hlsProps: s.hlsProps
      };
    });

    tMission.views = mViews;
    tableMissions.push(tMission);
    tSensors.forEach(s => tableMissions.push(s));
  });

  return tableMissions;
}


/**
 *  Translates Platforms to the format used by the PlatformTable
 * @param {Array} serverPlatforms 
 */
export function translatePlatforms(serverPlatforms) {

  let i = 0;
  let tablePlatforms = [];

  serverPlatforms.forEach(p => {
    const platformId = i++;
    const platform = {
      id: platformId,
      platform: /* p.name*/p.name,
      live: p.live,
      name: p.name,
      description: p.description,
      type: p.type,
      state: 'unknown',
      startTime: p.startTime,
      recordingStartTime: p.recordingStartTime,
    };

    let atLeastOneOnline = false;
    let atLeastOneOffline = false;
    let atLeastOneLost = false;

    const sensors = [];
    p.sensors.forEach(s => {

      const rInfo = {
        info: s.recorderInfo ? s.recorderInfo.info : undefined,
        error: s.error
      }

      sensors.push({
        id: i++,
        sensor: s.name,
        parentId: platformId,
        parentPlatformName: p.name,
        platformType: p.type,
        parentPlatformLive: p.live,
        live: s.live,
        mission: s.missionName,
        name: s.name,
        description: s.description,
        type: s.type,
        url: s.url,
        state: platformStates[`${ p.name}/${s.name}`] ? platformStates[`${ p.name}/${s.name}`] : 'unknown',
        config: platformConfigs[`${ p.name}/${s.name}`] ? platformConfigs[`${ p.name}/${s.name}`] : null,
        streamInfo: platformStreamInfos[`${ p.name}/${s.name}`] ? platformStreamInfos[`${ p.name}/${s.name}`] : null,
        startTime: s.startTime,
        recordingStartTime: s.recordingStartTime,
        recorderInfo: rInfo
      });
    });

    sensors.forEach(s => {
      if (s.state === 'online')
        atLeastOneOnline = true;
      else
        if (s.state === 'offline')
          atLeastOneOffline = true;
        else
          if (s.state === 'lost')
            atLeastOneLost = true;
    });

    if (atLeastOneLost)
      platform.state = 'lost';
    else
      if (atLeastOneOnline && (!atLeastOneOffline || p.sensors.length === 1))
        platform.state = 'online';
      else
        if (atLeastOneOnline && atLeastOneOffline)
          platform.state = 'partial';
        else
          if (!atLeastOneOnline && atLeastOneOffline)
            platform.state = 'offline';
          else
            if (!atLeastOneOnline && !atLeastOneOffline)
              platform.state = 'offline';

    tablePlatforms = [...tablePlatforms, platform, ...sensors];
  });

  return tablePlatforms;
}

/**
 * Translates Messages to the format used by the UserMessage Table
 * @param {Array} messages 
 */
export function translateUserMessages(messages) {

  let i = 0;
  const tableUserMessages = messages.map(m => {
    return {
      id: i++,
      messageId: m._id,
      title: m.title,
      sender: m.sender,
      senderName: m.sender.username,
      senderUserId: m.senderUserId,
      message: m.message,
      timestamp: new Date(parseInt(m._id.substring(0, 8), 16) * 1000)
    };
  });

  return tableUserMessages;
}

export const checkIfOnline = (server, dispatchApp) => {
  if (server.state === 'Offline') {
    dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: 'Cannot add mission. Server is offline' } });
    return false;
  }
  return true;
}

export function wsEmit(eventName, ...args) {

  try {
    ws.emit(eventName, ...args);
  } catch (error) {

  }
}

export default App;
